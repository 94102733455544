import React from 'react';
import { Header } from 'semantic-ui-react';
import Layout from '../components/layout';
import Helmet from '../components/Helmet';
import * as DefaultTemplate from '../components/ContentPages/DefaultTemplate';

const ContactPage = () => (
    <Layout
        showDisclosures={false}
    >
        <Helmet
            title="Contact Us"
            description="Get in touch with us."
            robots="noindex, follow"
        />
        <DefaultTemplate.Wrapper>
            <DefaultTemplate.Body>
                <Header as="h1" size="large">
                    Contact Us
                </Header>
                <p>
                    Questions? Comments? We&rsquo;d love to hear from you!
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    Email
                </Header>
                <p>
                    <a href="mailto:customerservice@debtconsolidation.com">customerservice@debtconsolidation.com</a>
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    Mailing Address
                </Header>
                <p>
                    Consolidation Resource, LLC
                    <br />
                    13014 N. Dale Mabry Hwy. Ste. 211
                    <br />
                    Tampa, FL 33618
                </p>
            </DefaultTemplate.Body>
        </DefaultTemplate.Wrapper>
    </Layout>
);

export default ContactPage;
